export const ANIMATIONS = {
  ANGER: "Anger",
  CLAP: "Clap",
  DANCE: "Dancing",
  FEAR: "Fear",
  FLYING: "Fly",
  HAND_RAISED: "HandRaised",
  IDLE: "Idle",
  LAUGH: "Laugh",
  RUN: "Run",
  RUN_BACKWARD: "RunBack",
  RUN_LEFT: "RunLeft",
  RUN_RIGHT: "RunRight",
  SIT_END: "SitEnd",
  SIT_START: "SitStart",
  SITTING: "Sitting",
  WALK: "Walk",
  WALK_BACKWARD: "WalkBack",
  WALK_LEFT: "WalkLeft",
  WALK_RIGHT: "WalkRight",
  WAVE: "Wave"
};

// @ts-ignore
AFRAME.registerComponent("avatar-animation", {
  animations: null,

  clock: null,
  mixer: null,
  isMe: false,
  isMobile: false,
  //   accelerationFront: 0,
  //   accelerationRight: 0,
  //   animationPlaying: ANIMATIONS.IDLE,

  //   boost: false,
  //   fly: false,
  //   dance: false,
  timeoutId: null,

  set(key, value) {
    this[key] = value;
  },

  get(key) {
    return this[key];
  },

  schema: {
    front: { default: 0 },
    right: { default: 0 },
    animationPlaying: { default: ANIMATIONS.IDLE }
    // flying: { default: false },
    // dancing: { default: false }
    // isRunning: { default: false }
  },

  init() {
    this.animations = new Map();
    this.mixer = new THREE.AnimationMixer(this.el.object3D?.parent || this.el.object3D);
    this.clock = new THREE.Clock();
    this.playerInfo = this.el.closest("[player-info]").getAttribute("player-info");
    this.userinput = AFRAME.scenes[0].systems.userinput;

    this.isMe = this.el.closest("#avatar-rig") != null;

    // this.isLocalPlayerInfo = this.el.id === "avatar-rig";
    this.playerSessionId = null;

    this.setAnimations(this.el.object3D);
  },

  tick() {
    this.mixer.update(this.clock.getDelta());
    this.el.setAttribute("avatar-animation", { front: this.playerInfo.front, right: this.playerInfo.right });
    if (this.playerInfo.currentAnimation) {
      this.el.setAttribute("avatar-animation", { animationPlaying: this.playerInfo.currentAnimation });
    }
    // const boostChanged = path === paths.actions.boost && value !== undefined;
    // if (boostChanged) {
    //   console.log(this.playerInfo.boost);
    //   if (!this.playerInfo.boost) {
    //     this.data.set("boost", value);
    //   } else {
    //     this.data.set("boost", value);
    //     // this.animation
    //   }
    // }
  },

  update() {
    if (this.isIdle()) return this.idle();
    this.walking();
  },

  timeoutDance() {
    // setTimeout(function () {
    console.log(this.dance);
    this.dance = false;
    console.log(this.dance);
    this.idle();
    // }, 1000);
  },

  idle() {
    this.resetAll(ANIMATIONS.IDLE);
    this.setEffectiveWeight(this.data.currentAnimation, 1);
    // [
    //   [ANIMATIONS.DANCE, this.data.dancing ? 1 : 0],
    //   [ANIMATIONS.FLYING, this.data.flying ? 1 : 0],
    //   [ANIMATIONS.IDLE, !this.data.flying && !this.data.dance ? 1 : 0]
    // ].forEach(([animationName, value]) => this.setEffectiveWeight(animationName, value));
  },

  resetAll(...ignore) {
    this.animations.forEach(animation => {
      if (ignore.includes(animation.getClip().name)) return;
      animation.setEffectiveWeight(0);
    });
  },

  isIdle() {
    return this.data.front === 0 && this.data.right === 0 && this.playerInfo.isIdle;
  },

  walking() {
    // console.log("animation ", this.playerInfo.boost);
    [
      [ANIMATIONS.WALK, !this.playerInfo.boost && this.data.front],
      [ANIMATIONS.WALK_BACKWARD, !this.playerInfo.boost && -this.data.front],
      [ANIMATIONS.WALK_LEFT, !this.playerInfo.boost && -this.data.right],
      [ANIMATIONS.WALK_RIGHT, !this.playerInfo.boost && this.data.right],
      [ANIMATIONS.RUN, this.playerInfo.boost && this.data.front],
      [ANIMATIONS.RUN_BACKWARD, this.playerInfo.boost && -this.data.front],
      [ANIMATIONS.RUN_LEFT, this.playerInfo.boost && -this.data.right],
      [ANIMATIONS.RUN_RIGHT, this.playerInfo.boost && this.data.right]
    ].forEach(([animationName, value]) => this.setEffectiveWeight(animationName, value));
  },

  setAnimations(object3D) {
    if (object3D.parent == null) return;
    if (object3D.animations.length === 0) return this.setAnimations(object3D.parent);

    object3D.animations.forEach(animation => {
      this.animations.set(animation.name, this.mixer.clipAction(animation));
      this.animations.get(animation.name).play();
      this.setEffectiveWeight(animation.name, 0);
    });
    this.setEffectiveWeight(ANIMATIONS.IDLE, 1);
  },

  setEffectiveWeight(animationName, weight) {
    this.animations.get(animationName)?.setEffectiveWeight(weight);
  },
  //here if you are walking and then press shift it works perfectly
  // but if you press shift first then run it wont work
  setBoostValue(value) {
    console.log(this.playerInfo.boost, this.playerInfo.isLocalPlayerInfo);
    // if (!this.playerInfo.isLocalPlayerInfo) return;
    if (!this.isMe) return;
    if (!this.playerInfo.boost && value) {
      this.playerInfo.boost = true;
      this.update();
    } else if (!value && this.playerInfo.boost) {
      this.playerInfo.boost = false;
      this.update();
    }
  }
});
