import React from "react";
import PropTypes from "prop-types";
import styles from "./IframeContainer.scss";
import { Column } from "../../layout/Column";
import { FormattedMessage } from "react-intl";

export function IframeContainer({ toggleIframeOpen, src, isIframeForMobile }) {
  return (
    <>
      <div className={styles.backdropContainer} onClick={() => toggleIframeOpen()}></div>
      <Column padding grow className={!isIframeForMobile ? styles.contentContainer : styles.contentContainerSmall}>
        <iframe
          src={src ?? ""}
          title="ABPlive"
          height={"auto"}
          className={styles.iframe}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;camera;microphone;"
          allowFullScreen
        />
        <div
          onClick={() => {
            toggleIframeOpen();
          }}
          className={(styles.modalContainer, styles.iframeButton)}
        >
          <FormattedMessage id="room.iframe-button-close-now" defaultMessage="वापस जाएं" />
        </div>
      </Column>
    </>
  );
}

IframeContainer.propTypes = {
  toggleIframeOpen: PropTypes.func,
  src: PropTypes.string.isRequired,
  isIframeForMobile: PropTypes.bool.isRequired
};
